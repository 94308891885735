import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import { TitleContentWrapper } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import RichText from '@components/RichText'
import { Heading1, Super2 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { animation } from '@styles/vars/animation.style'

const TitleContent = ({
  title,
  content,
  alignment,
  pageContent,
  largeTitle = false,
  center = false,
}) => {
  const stack = alignment === 'Stack'

  return (
    <TitleContentWrapper pageContent={pageContent} center={center}>
      <Container>
        <Grid>
          <GridItem
            desk={largeTitle || center ? 4 : 3}
            deskStart={center ? 5 : 0}
          >
            {largeTitle ? (
              <Super2>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Super2>
            ) : (
              <Heading1>
                <AnimateSplitText>{title}</AnimateSplitText>
              </Heading1>
            )}
            <Spacer size={24} />
          </GridItem>
          <GridItem desk={6} deskStart={stack ? (center ? 4 : 1) : 7}>
            <RichText content={content} delay={animation.blockDuration} />
            <Spacer size={24} />
          </GridItem>
        </Grid>
      </Container>
    </TitleContentWrapper>
  )
}

TitleContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  alignment: PropTypes.string,
  pageContent: PropTypes.bool,
  largeTitle: PropTypes.bool,
  center: PropTypes.bool,
}

TitleContent.defaultProps = {
  pageContent: false,
}

export default TitleContent
